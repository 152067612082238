import React, { useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';
import {useTranslation} from 'react-i18next';

const Footer = ({anchor}) => {
const footerRef = useRef();

useEffect(() => {
  if(anchor?.includes('feedback')) {
    setTimeout(() => {
      footerRef.current.scrollIntoView({ behavior: "smooth", inline: 'end'});
    }, 1000);
  }
})

  const {t} = useTranslation();
  return (
    <footer id="feedback">
      <Container>
        <Row className="justify-content-center">
          <Col xs={10} lg={6} className="d-flex flex-column align-items-center contactSide">
            <span className="contactTitle">{t('contactTitle.1')}</span>
            <p className="contactText">{t('contactText.1')}</p>
            <a href="mailto:dokumaciyagiz@gmail.com" className="contactBtn">{t('contactBtn.1')}</a>
          </Col>
        </Row>
      </Container>
      <div ref={footerRef}/>
    </footer>
  )
}

export default Footer;