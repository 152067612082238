import React from 'react'
import Project from './Project/Project';

import {Row} from 'react-bootstrap';
import './Projects.css';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const Projects = () => {
  const {t} = useTranslation();
  const projectsArray = [
    {id: 1, title: i18next.t('ProjectTitle4.1'), text: i18next.t('ProjectText4.1'), category: "Swift", url: "https://github.com/yagizdo/MunchMate"},
    {id: 2, title: i18next.t('ProjectTitle6.1'), text: i18next.t('ProjectText6.1'), category: "Flutter", url: "https://github.com/yagizdo/crypto_tracker_app"},
    {id: 3, title: i18next.t('ProjectTitle2.1'), text: i18next.t('ProjectText2.1'), category: "Flutter", url: "https://apps.apple.com/us/app/handbook-for-valorant/id1624518737"},
    {id: 4, title: i18next.t('ProjectTitle8.1'), text: i18next.t('ProjectText8.1'), category: "Swift", url: "https://github.com/yagizdo/PrimeVideoDesignWithSwift"},
    {id: 5, title: i18next.t('ProjectTitle10.1'), text: i18next.t('ProjectText10.1'), category: "Flutter", url: "https://github.com/yagizdo/github-api-app"},

  ];
  return (
    <div className="projectsSide" id="projects">
      <h2 className="sectionTitle"> {t('Projects.1')} </h2>
      <Row>
        <Project projects= {projectsArray}/>
      </Row>
      <a
        href="https://github.com/yagizdo?tab=repositories"
        rel="noopener noreferrer"
        target="_blank"
        className="showAllBtn"
      >
        {t('SeeAll.1')}
      </a>
    </div>
  )
}

export default Projects;
