import React from 'react';
import Post from './Post/Post';
import { Row } from 'react-bootstrap';
import './Blog.css';

import {useTranslation} from 'react-i18next';

const postsArray = [
    { id: "1", img : "/img/motivation.jpg", title : "#100daysofcode Challenge serüveni", text: "Kısaca kendimden bahsedip serüvenime geçmek istiyorum. Bendeniz Yılmaz Yağız Dokumacı, 22 yaşındayım.", url: "https://yagizdo.medium.com/100daysofcode-challenge-ser%C3%BCveni-3c6a0323cc6f"},
      { id: "2", img : "/img/giithb.jpg", title : "Githubta fork edilen proje nasıl güncellenir?", text: "Githubta herhangi birinden fork ettiğimiz bir projeye herhangi bir güncelleme..", url: "https://yagizdo.medium.com/githubta-fork-edilen-proje-nas%C4%B1l-g%C3%BCncellenir-2e49b9132445"},
   { id: "3", img : "/img/pizza.jpeg", title : "Bakırköyün en iyi 5 Pizzacısı", text: "Bugün kendi damak zevkime ve puanlandırma yöntemime göre Bakırköy de denediğim pizzacılar arasında ki ilk 5'i yazacağım.", url: "https://yagizdo.medium.com/bak%C4%B1rk%C3%B6y%C3%BCn-en-iyi-5-pizzac%C4%B1s%C4%B1-4073b6a1213f"},
  { id: "4", img : "/img/logitech.webp", title : "Logitech destek maceram", text: "Merhaba sevgili dostlar. Bugün sizlere geçtiğimiz haftalarda yaşadığım Logitech Destek sürecimi anlatmak istiyorum.", url: "https://yagizdo.medium.com/logitech-destek-maceram-563a100ecad5",},{ id: "5", img : "/img/flutter_page_img.webp", title : "Swift — Storyboard Sayfalar Arası Geçiş ve Veri Transferi", text: "Bu yazımda sizlere sayfalar (View Controllerlar) arası geçişi nasıl yaparız ve nasıl veri taşırız onu anlatmak istiyorum.", url: "https://yagizdo.medium.com/swift-storyboard-sayfalar-aras%C4%B1-ge%C3%A7i%C5%9F-ve-veri-transferi-d2f73c95a86a",}


]

const Blog = () => {
  const {t} = useTranslation();
  return (
    <div className="blogSide" id="blog">
      <h2 className="sectionTitle">Blog</h2>
      <Row>
        <Post posts={postsArray}/>
      </Row>
      <a
        href="https://blog.yagizdokumaci.com"
        rel="noopener noreferrer"
        target="_blank"
        className="showAllBtn"
      >
        {t('SeeAll.1')}
      </a>
    </div>
  )
}

export default Blog;